<route>
{
  "meta": {
    "permission": [
      "expenses.view_expense"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12" sm="3">
        <i-date-picker
          v-model="start"
          :label="$t('start_date')"
          color="primary"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <i-date-picker
          v-model="end"
          :label="$t('end_date')"
          color="primary"
          :min="start"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="account"
          :name="$t('account')"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.account"
            outlined
            :error-messages="errors[0]"
            :label="$t('account')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <i-table
      :title="$tc('expense', 2)"
      :headers="headers"
      app="expenses.expense"
      api="expense"
      :opt="{
        date_from: this.form.date_from,
        date_to: this.form.date_to,
        account: this.form.account
      }"
      dontCreate
      dontRemove
      dontEdit
      dontSearch
    >
      <template v-slot:item.date="{ item }">
        <span v-text="item.date != null ? dateFormat(item.date) : ''"></span>
      </template>
      <template v-slot:extraToolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-file-excel"
          :title="$t('download', { value: $tc('expense', 2) })"
          color="text-dark"
          @click="getExpensesExcel()"
        >
        </i-btn>
      </template>
    </i-table>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      modalBD: false,
      modalBD2: false,
      start: '',
      end: '',
      form: {
        date_from: '',
        date_to: '',
        account: ''
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc('category', 1),
          value: 'category.name'
        },
        {
          text: this.$t('account'),
          value: 'account.name'
        },
        {
          text: this.$t('date'),
          value: 'date'
        },
        {
          text: this.$t('operator'),
          value: 'operator'
        },
        {
          text: this.$t('description'),
          value: 'description'
        },
        {
          text: this.$tc('cost', 1),
          value: 'cost'
        },
        {
          text: this.$t('reference'),
          value: 'reference'
        }
      ]
    }
  },
  methods: {
    getExpensesExcel() {
      this.downloadFile({
        api: 'expense',
        method: 'excel',
        filename: 'Expenses',
        action: 'download',
        ext: 'xlsx',
        opt: {
          pk: 1,
          params:
            '?account=' +
            this.form.account +
            '&date_from=' +
            this.form.date_from +
            '&date_to=' +
            this.form.date_to
        }
      })
    }
  },
  watch: {
    start: {
      handler(val) {
        if (val != null && val != '' && val != undefined) {
          this.form.date_from = this.dateFormat(val)
        }
      }
    },
    end: {
      handler(val) {
        if (val != null && val != '' && val != undefined) {
          this.form.date_to = this.dateFormat(val)
        }
      }
    }
  }
}
</script>
